import React, { useState } from 'react';
import {useSelector} from 'react-redux';
import {Link,useLocation } from 'react-router-dom';
import {
  HomeOutlined,
  MailOutlined ,
  TeamOutlined,
  IdcardOutlined,
  SettingOutlined
} from '@ant-design/icons';
import { Layout, Menu } from 'antd';
const { Sider} = Layout;

const Sidebar = () => {
  //Get collapsed from collapsedReducer
  const collapsed = useSelector((state:any) => state.collapsed.collapsed);
  const [current, setCurrent] = useState('mail');
  const location = useLocation();
  return (
    <Sider trigger={null} collapsible collapsed={collapsed}>
      <div className="logo">
        <img src="/mybrainguideLogo.svg" 
        style={{width:"70%", padding:"10px", marginLeft: "auto", marginRight:"auto", display: "block"}}/>
      </div>
      <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={[location.pathname.slice(1)]}
        items={[
          // {
          //   key: 'dashboard',
          //   icon: <HomeOutlined />,
          //   label: <Link to='/dashboard'>Dashboard</Link>
          // },
          {
            key: 'brainGuide',
            icon: <TeamOutlined />,
            label: `BrainGuide`,
            children : [
              {
                key: 'visitors',
                icon: <TeamOutlined />,
                label: <Link to='/visitors'>Visitors</Link>
              },
              {
                key: 'shared-pdf',
                icon: <IdcardOutlined />,
                label: <Link to='/shared-pdf'>Share PDF</Link>,
              }
            ]
          },
          {
            key: 'prevention',
            icon: <TeamOutlined />,
            label: `Prevention`,
            children : [
              {
                key: 'prevention-visitors',
                icon: <TeamOutlined />,
                label: <Link to='/prevention/visitors'>Visitors</Link>
              },
              {
                key: 'prevention-shared-pdf',
                icon: <IdcardOutlined />,
                label: <Link to='/prevention/shared-pdf'>Share PDF</Link>
              }
            ]
          },
          {
            key: 'dep',
            icon: <TeamOutlined />,
            label: `Dep Portal`,
            children : [
              {
                key: 'dep-visitors',
                icon: <TeamOutlined />,
                label: <Link to='/dep/visitors'>Visitors</Link>
              },
              {
                key: 'dep-shared-pdf',
                icon: <IdcardOutlined />,
                label: <Link to='/dep/shared-pdf'>Share PDF</Link>
              }
            ]
          },
          {
            key: 'follow-up-email',
            icon: <MailOutlined />,
            label: <Link to='/follow-up-email'>Follow UP Emails</Link>,
          },
          {
            key: 'setting',
            icon: <SettingOutlined />,
            label: <Link to='/setting'>Setting</Link>,
          },
        ]}
      />
    </Sider>
  )
}

export default Sidebar